import React, { memo } from 'react'
import styled from '@emotion/styled'
import { Box } from '../../ui'

const LogoContainer = styled(Box)`
  width: 210px;
  height: 26px;
  position: relative;

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const LogoText = styled(Box)`
  top: 0;
  left: 0;
  z-index: 2;
`

const LogoMask = styled(Box)`
  z-index: 1;
  overflow: hidden;
`

const LogoHeart = styled(Box)`
  @keyframes moveHeart{
    0%{
        transform:translateY(100%)
    }
    50%{
        transform:translateY(100%)
    }
    62.5%{
        transform:translateY(0)
    }
    100%{
        transform:translateY(0)
    }
  }

  position: absolute;
  top: 0;
  left: 0;
  animation-name: moveHeart;
  animation-duration: 6000ms;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  width: 100%;
  height: 100%;
`

const LogoLove = styled(Box)`
  @keyframes moveLove{
    0%{
        transform:translateY(0)
    }
    37.5%{
        transform:translateY(0)
    }
    50%{
        transform:translateY(100%)
    }
    100%{
        transform:translateY(100%)
    }
  }

  position: absolute;
  top: 0;
  left: 0;
  animation-name: moveLove;
  animation-duration: 6000ms;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  width: 100%;
  height: 100%;
`

const FullLogo = memo(() => (
  <LogoContainer>
    <LogoText
      as="span"
      color="textWhite"
    >
      <svg
        fill="currentColor"
        viewBox="0 0 799 115"
      >
        <path
          d="M439.1 104.6H148.6V114.3H439.1V104.6Z"
        />
        <path
          d="M0.799991 24.8C0.499991 23.6 1.09999 22.7 2.29999 22.7H12.3C13 22.7 13.7 23.3 13.8 23.9L21.2 52.9H21.5L34.7 22.8C35 22.3 35.5 21.9 36.2 21.9H37.7C38.5 21.9 39 22.3 39.2 22.8L52.4 52.9H52.7L60.1 23.9C60.3 23.3 61 22.7 61.6 22.7H71.6C72.8 22.7 73.5 23.6 73.1 24.8L56.9 82.2C56.7 82.9 56 83.4 55.4 83.4H54C53.4 83.4 52.8 83 52.5 82.5L36.9 47.5H36.6L21 82.5C20.7 83 20.1 83.4 19.5 83.4H18.1C17.4 83.4 16.7 82.9 16.6 82.2L0.799991 24.8Z"
        />
        <path
          d="M80.8 24.3C80.8 23.4 81.5 22.7 82.4 22.7H117.8C118.7 22.7 119.4 23.5 119.4 24.3V33.6C119.4 34.5 118.7 35.2 117.8 35.2H94.1V45.8H113.6C114.5 45.8 115.2 46.6 115.2 47.4V56.7C115.2 57.6 114.4 58.3 113.6 58.3H94.1V70H117.8C118.7 70 119.4 70.8 119.4 71.6V80.9C119.4 81.8 118.7 82.5 117.8 82.5H82.4C81.5 82.5 80.8 81.7 80.8 80.9V24.3Z"
        />
        <path
          d="M488.3 33.2H476.1C475.2 33.2 474.5 32.4 474.5 31.6V22.3C474.5 21.4 475.2 20.7 476.1 20.7H514C514.9 20.7 515.6 21.5 515.6 22.3V31.6C515.6 32.5 514.9 33.2 514 33.2H501.8V78.7C501.8 79.6 501 80.3 500.2 80.3H490C489.1 80.3 488.4 79.5 488.4 78.7V33.2H488.3Z"
        />
        <path
          d="M524.2 22.3C524.2 21.4 525 20.7 525.8 20.7H536C536.9 20.7 537.6 21.5 537.6 22.3V78.7C537.6 79.6 536.8 80.3 536 80.3H525.8C524.9 80.3 524.2 79.5 524.2 78.7V22.3Z"
        />
        <path
          d="M548.9 72.3L552.9 65.2C553.6 64.1 555.2 64.3 555.8 64.7C556.1 64.9 562.3 69.4 567.9 69.4C571.3 69.4 573.8 67.3 573.8 64.3C573.8 60.7 570.8 58 565 55.7C557.7 52.8 548.5 47.1 548.5 36.9C548.5 28.5 555.1 19.9 568.2 19.9C577.1 19.9 583.9 24.4 586.4 26.3C587.4 26.9 587.3 28.4 586.9 29L582.6 35.6C582 36.5 580.5 37.3 579.7 36.7C578.9 36.3 572.7 31.6 567.5 31.6C564.4 31.6 562.1 33.6 562.1 35.9C562.1 39.1 564.7 41.4 571.4 44.2C578.1 46.8 588.6 52 588.6 63.6C588.6 72.4 581 81.3 568.5 81.3C557.4 81.3 551.3 76.7 549.3 74.7C548.5 73.8 548.2 73.5 548.9 72.3Z"
        />
        <path
          d="M593.7 77.2L616.4 33.4V33.2H597.8C596.9 33.2 596.2 32.4 596.2 31.6V22.3C596.2 21.4 597 20.7 597.8 20.7H634C634.9 20.7 635.6 21.5 635.6 22.3V23.4L613.7 67.7V67.9H632.3C633.2 67.9 633.9 68.7 633.9 69.5V78.8C633.9 79.7 633.2 80.4 632.3 80.4H595.3C594.4 80.4 593.7 79.6 593.7 78.8V77.2Z"
        />
        <path
          d="M637.6 78.2L664.3 20.8C664.6 20.3 665.2 19.9 665.7 19.9H666.6C667.2 19.9 667.8 20.3 668 20.8L694.7 78.2C695.2 79.3 694.5 80.4 693.3 80.4H683.8C682.3 80.4 681.6 79.9 680.8 78.3L677.7 71.6H654.5L651.4 78.4C651 79.4 650 80.4 648.3 80.4H639C637.7 80.4 637.1 79.3 637.6 78.2ZM672.6 59.9L666.1 45.8H666L659.6 59.9H672.6Z"

        />
        <path
          d="M706.3 33.2H694.1C693.2 33.2 692.5 32.4 692.5 31.6V22.3C692.5 21.4 693.2 20.7 694.1 20.7H732C732.9 20.7 733.6 21.5 733.6 22.3V31.6C733.6 32.5 732.9 33.2 732 33.2H719.8V78.7C719.8 79.6 719 80.3 718.2 80.3H708C707.1 80.3 706.4 79.5 706.4 78.7V33.2H706.3Z"
        />
        <path
          d="M768 19.9C785.1 19.9 798.7 33.6 798.7 50.7C798.7 67.8 785.1 81.3 768 81.3C750.9 81.3 737.4 67.7 737.4 50.7C737.4 33.6 751 19.9 768 19.9ZM768 67.6C777.4 67.6 785.1 59.9 785.1 50.6C785.1 41.2 777.4 33.5 768 33.5C758.7 33.5 751 41.3 751 50.6C751.1 59.9 758.7 67.6 768 67.6ZM759.7 14.7L757.9 11.1C757.6 10.4 758 9.80003 758.4 9.50003L772.9 0.600032C773.4 0.300032 774.7 0.100035 775.1 0.900035L778 5.90003C778.7 7.20003 778.2 8.00003 777.5 8.50003L762 15.5C760.9 16.1 760.1 15.5 759.7 14.7Z"
        />
      </svg>
    </LogoText>
    <LogoMask
      as="span"
      color="accent.50"
    >
      <LogoHeart as="span">
        <svg
          fill="none"
          viewBox="0 0 799 130"
        >
          <path
            fill="currentColor"
            d="M273.093 99L230.842 48.2308C226.206 42.6286 223.757 35.3634 224.019 27.748C224.194 20.1326 227.081 13.0424 231.979 7.79045C236.703 2.80106 242.826 0 249.299 0C256.297 0 263.033 3.32626 267.757 9.01591L273.005 15.3183L278.254 9.01591C282.977 3.23873 289.713 0 296.711 0C303.184 0 309.307 2.71353 314.031 7.79045C318.929 13.0424 321.816 20.1326 321.991 27.748C322.166 35.3634 319.804 42.6286 315.168 48.2308L273.093 99Z"
          />
        </svg>
      </LogoHeart>
      <LogoLove as="span">
        <svg
          fill="currentColor"
          viewBox="0 0 799 115"
        >
          <path
            d="M192.6 23.2C192.6 22.3 193.3 21.6 194.2 21.6H204.4C205.3 21.6 206 22.4 206 23.2V68.8H226.3C227.2 68.8 227.9 69.6 227.9 70.4V79.7C227.9 80.6 227.2 81.3 226.3 81.3H194.2C193.3 81.3 192.6 80.5 192.6 79.7V23.2Z"
          />
          <path
            d="M261.4 20.8C278.5 20.8 292.2 34.6 292.2 51.7C292.2 68.8 278.5 82.4 261.4 82.4C244.3 82.4 230.7 68.8 230.7 51.7C230.7 34.6 244.3 20.8 261.4 20.8ZM261.4 68.6C270.8 68.6 278.5 60.9 278.5 51.6C278.5 42.2 270.8 34.4 261.4 34.4C252.1 34.4 244.4 42.2 244.4 51.6C244.4 60.9 252 68.6 261.4 68.6Z"
          />
          <path
            d="M293.4 23.8C292.9 22.7 293.6 21.6 294.9 21.6H306.3C307 21.6 307.6 22.1 307.8 22.5L321.9 53.7H322.4L336.5 22.5C336.7 22.1 337.3 21.6 338 21.6H349.4C350.7 21.6 351.4 22.7 350.9 23.8L324.1 81.3C323.8 81.8 323.3 82.2 322.6 82.2H321.7C321 82.2 320.5 81.8 320.2 81.3L293.4 23.8Z"
          />
          <path
            d="M356.8 23.2C356.8 22.3 357.5 21.6 358.4 21.6H393.8C394.7 21.6 395.4 22.4 395.4 23.2V32.5C395.4 33.4 394.7 34.1 393.8 34.1H370.1V44.7H389.6C390.5 44.7 391.2 45.5 391.2 46.3V55.6C391.2 56.5 390.4 57.2 389.6 57.2H370.1V68.7H393.8C394.7 68.7 395.4 69.5 395.4 70.3V79.6C395.4 80.5 394.7 81.2 393.8 81.2H358.4C357.5 81.2 356.8 80.4 356.8 79.6V23.2Z"
          />
        </svg>

      </LogoLove>
    </LogoMask>
  </LogoContainer>
))

export default FullLogo
