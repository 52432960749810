import React, { memo } from 'react'
import styled from '@emotion/styled'
import {
  Box, Grid, GridItem, Heading, Text, Link, List, ListItem, Parsed, Loader,
} from '../ui'
import { EventDuration } from '../events'
import { PostThumbnailRemote } from '../posts'

const BlockLink = styled(Link)`
  display: block;
`

const StyledArticle = styled('article')`
  &:hover {
    background-color: ${(props) => props.theme.colors.secondary[50]};
  }
`

const mapPosts = (posts) => posts.map((post) => ({
  ...post,
  type: post.type.toLowerCase(),
  meta: {
    Post: post.meta,
    Event: <EventDuration {...post} />,
    Location: [post.town, post.address].filter((v) => !!v).join(', '),
  }[post.type],
}))

const SearchResult = ({
  uri, title, meta = '', featuredImage = {},
}) => (
  <StyledArticle>
    <BlockLink to={uri}>
      <Grid
        mx={0}
        py={2}
      >
        <GridItem
          px={2}
          minWidth={50}
        >
          <PostThumbnailRemote
            height={50}
            width={50}
            alt={title}
            src={featuredImage.node.src}
          />
        </GridItem>
        <GridItem
          px={2}
          flex={1}
        >
          <Parsed
            fontSize={2}
            color="text"
            component={Heading}
          >
            {title}
          </Parsed>
          <Text
            mt={1}
            variant="description"
          >
            {meta}
          </Text>
        </GridItem>
      </Grid>
    </BlockLink>
  </StyledArticle>
)

const SearchResultsCompact = ({
  loading, error, searchResults, ...props
}) => {
  if (loading || !searchResults) {
    return (
      <Box
        p={4}
        textAlign="center"
      >
        <Loader />
      </Box>
    )
  }

  if (error || searchResults.length < 1) {
    return (
      <Text
        p={4}
        textAlign="center"
      >
        Nincs találat.
      </Text>
    )
  }

  return (
    <List {...props}>
      {
        mapPosts(searchResults)
          .map((post) => (
            <ListItem key={post.uri}>
              <SearchResult
                {...post}
              />
            </ListItem>
          ))
      }
    </List>
  )
}

export default memo(SearchResultsCompact)
