import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import styled from '@emotion/styled'
import { Box, Heading, Link } from '../../ui'
import useSiteTitle from '../../../hooks/useSiteTitle'
import FullLogo from './FullLogo'

const LogoDivider = styled(Box)`
content: '';
display: inline-block;
width: 3px;
height: 24px;
background-color: ${({ theme }) => theme.colors.background.body};
margin-left: 16px;
margin-right: 10px;
`

const PrimaryLogo = (props) => {
  const title = useSiteTitle()

  return (
    <Heading
      as="header"
      {...props}
    >
      <Link
        to="/"
        title={title}
      >
        <Box
          display={['block', 'none']}
        >
          <StaticImage
            alt={title}
            height={44}
            quality={90}
            loading="eager"
            placeholder="none"
            src="../../../data/images/identity/logo-standalone.png"
          />
        </Box>
        <Box
          display={['none', 'flex']}
        >
          <FullLogo />
          <LogoDivider />
        </Box>
      </Link>
    </Heading>
  )
}

export default PrimaryLogo
