import React, { memo } from 'react'
import { ContainerFixed } from '../ui'
import { AppFooter } from './footer'
import { AppHeader } from './header'
import CookieConsentFloating from './CookieConsentFloating'
import { MetaTagsDefault } from '../seo'
import { CalendarContextProvider } from '../events'
import { SearchPreviewContextProvider } from '../search'
import GemiusScript from '../GemiusScript'
import TagmanagerScript from '../TagmanagerScript'
import AdOceanScript from '../AdOceanScript'
import CookieConsent from './CookieConsent'

const AppLayout = ({ children }) => (
  <SearchPreviewContextProvider>
    <CalendarContextProvider>
      <MetaTagsDefault />
      <GemiusScript />
      <TagmanagerScript />
      <AdOceanScript />
      <AppHeader />
      <ContainerFixed
        my={4}
        as="main"
      >
        {children}
      </ContainerFixed>
      <AppFooter />

      <CookieConsent />
    </CalendarContextProvider>
  </SearchPreviewContextProvider>
)

export default memo(AppLayout)
