import React from 'react'
import styled from '@emotion/styled'
import { SearchForm } from '../../search'
import {
  ContainerFixed, Grid, GridItem, Box, GRID_DEFAULT_SPACING,
  Link,
} from '../../ui'
import PrimaryLogo from './PrimaryLogo'
import SecondaryNav from './SecondaryNav'
import ValueProposition from './ValueProposition'
import NavLayoutResponsive from './NavLayoutResponsive'
import magnifierIcon from '../../../data/images/icons/icon-magnifier-light.png'

const StyledRoot = styled(Box)`
  background-color: ${({ theme }) => theme.colors.primary[50]};
  color: ${(props) => props.theme.colors.textWhite};
`
const Img = styled('img')`
  width: 24px;
  height: auto;
  opacity: .7;

  &:hover {
    opacity: 1;
  }
`

const NavContainer = styled(Box)`
  border-top: 1px solid ${(props) => props.theme.colors.secondary[100]};
`

const AppHeader = () => (
  <>
    <StyledRoot>
      <ContainerFixed py={2}>
        <Grid
          alignItems="center"
          justifyContent="flex-start"
        >
          <GridItem
            pr={0}
            position="relative"
            flex={[1, 'none']}
          >
            <PrimaryLogo />
            <Box
              display="none"
              position="absolute"
              left={[0, 'auto']}
              right={['auto', 0]}
              bottom={['auto', -10]}
            >
              <ValueProposition />
            </Box>
          </GridItem>
          {/* menü */}
          <GridItem 
            px={[0, GRID_DEFAULT_SPACING]}
            flex={[6, 'none']}
            >
            <NavLayoutResponsive />
          </GridItem>
          <GridItem
            pl={0}
            py={[2, 0]}
            width={['100%', 'auto']}
            display={['none', 'block']}
          >
            <SearchForm />
            
          </GridItem>
          <GridItem>
          <Link
              to="/kereso"
              title="Keress a Tisza-tónál..."
              display={['block', 'none']}
              flex={1}
            >
              <Img
                alt=""
                width={24}
                height={24}
                src={magnifierIcon}
              />
            </Link>
          </GridItem>
        </Grid>
      </ContainerFixed>
    </StyledRoot>
    <NavContainer
      top="0"
      zIndex={10}
      as="header"
      bg="background.body"
      position="sticky"
    >
      <ContainerFixed>
        <Grid
          alignItems="center"
          justifyContent="space-between"
        >

          <GridItem
            display={['none', 'none', 'none', 'block']}
          >
          </GridItem>
        </Grid>
      </ContainerFixed>
    </NavContainer>
  </>
)

export default AppHeader
