import React from 'react'
import { Helmet } from 'react-helmet'

const identifier = process.env.GEMIUS_IDENTIFIER

const createEmbedCode = (id) => `
var pp_gemius_identifier = '${id}';
// lines below shouldn't be edited
function gemius_pending(i) { window[i] = window[i] || function() {var x = window[i+'_pdata'] = window[i+'_pdata'] || []; x[x.length]=arguments;};};
gemius_pending('gemius_hit'); gemius_pending('gemius_event'); gemius_pending('gemius_init'); gemius_pending('pp_gemius_hit'); gemius_pending('pp_gemius_event'); gemius_pending('pp_gemius_init');
(function(d,t) {try {var gt=d.createElement(t),s=d.getElementsByTagName(t)[0],l='http'+((location.protocol=='https:')?'s':''); gt.setAttribute('async','async');
gt.setAttribute('defer','defer'); gt.src=l+'://gahu.hit.gemius.pl/xgemius.js'; s.parentNode.insertBefore(gt,s);} catch (e) {}})(document,'script');
`

const GemiusScript = () => (
  identifier
    ? (
      <Helmet>
        <script type="text/javascript">{createEmbedCode(identifier)}</script>
      </Helmet>
    ) : null
)

export default GemiusScript
