import React from 'react'
import { Helmet } from 'react-helmet'

const embed = `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-T7RP85XXME');`

const TagmanagerScript = () => (
  <Helmet>
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=G-T7RP85XXME"
    />
    <script>{embed}</script>
  </Helmet>
)

export default TagmanagerScript
