import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

const AdOceanScript = () => {

  return (
    <Helmet>
      <script type="text/javascript" src="https://indexhu.adocean.pl/files/js/ado.js"/>
    </Helmet>
  )
}

export default AdOceanScript
