export const API_URL = `${process.env.WP_BASE_URL}/graphql`
export const GLOBAL_SEARCH_URL = `${API_URL}/graphql`
export const EVENTS_SEARCH_URL = `${API_URL}/graphql`
export const LOCATIONS_SEARCH_URL = `${API_URL}/graphql`
export const ADOCEAN_IDS = {
  desktop: {
    master: {
      mainpage: 'oogAdreuN8fwd9JVqatZx2QNQeGCFIbG3IbKYzIuK7z.R7',
      subpage: 'BemWVfCmkmM04lYVPREMURozHbabwUdmwJiFTv1xg3j.H7',
    },
    magnumMainPage: 'adoceanindexhukmdtgoopio',
    zone1MainPage: 'adoceanindexhunlmmdrelyt',
    zone2MainPage: 'adoceanindexhuxmkjkliusj',
    magnumSubPage: 'adoceanindexhutjopmwqrnz',
    zone1SubPage: 'adoceanindexhuwihjjjhnze',
    zone2SubPage: 'adoceanindexhuqkfgqtkgdy',
  },
  mobile: {
    master: {
      mainpage: 'Uaj8LR8sjRM6NrmtwF8cVtyncD5u4gO8GNvDGoQ6N7f.K7',
      subpage: 'PwKaLHjzo_PSNZ1ZDRNbIaf5EDU4DkOLaN_wd7DrDP..N7',
    },
    magnumMainPage: 'adoceanindexhuscmgcinuwi',
    zone2MainPage: 'adoceanindexhuvbfqokdqbn',
    zone1MainPage: 'adoceanindexhupddnfvgjqh',
    magnumSubPage: 'adoceanindexhuoppmetfsry',
    zone2SubPage: 'adoceanindexhulahthqpgwx',
    zone1SubPage: 'adoceanindexhuyaojlnjlgs',
  },
}
