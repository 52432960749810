import galite from 'ga-lite'
import Cookies from 'universal-cookie'
import { isMobile } from 'react-device-detect'
import AppWrapper from './src/AppWrapper'
import { ADOCEAN_IDS } from './src/config'
import '@fontsource/merriweather'
import '@fontsource/noto-sans'

const cookies = new Cookies()
const googleAnalyticsCookieName = 'tiszato-gdpr-google-analytics'
let isTrackingInitialized = false

const isTrackingEnabled = () => process.env.GA_TRACKING_ID && cookies.get(googleAnalyticsCookieName) === 'true'

const initializeTracking = () => {
  if (!isTrackingInitialized) {
    galite('create', process.env.GA_TRACKING_ID, 'auto')
    galite('set', 'anonymizeIp', true)

    isTrackingInitialized = true
  }
}

export const onRouteUpdate = () => {
  setTimeout(() => {
    if (isTrackingEnabled()) {
      initializeTracking()
      galite('send', 'pageview')
    }
    if (typeof googletag !== 'undefined') {
      googletag.destroySlots()
    }

    const adoMasterProp = window.location.pathname === '/' ? 'mainpage' : 'subpage'
    const bannerType = isMobile ? 'mobile' : 'desktop'
    const adoMaster = ADOCEAN_IDS[bannerType].master[adoMasterProp]

    const adoMasters = Object.keys(ado.masterSlaves)
    if (!ado.masterSlaves[adoMaster]) {
      const tagStr = document.querySelector('meta[name="keywords"]') ? document.querySelector('meta[name="keywords"]').getAttribute('content').replace(/\s/g, '') : ''
      ado.master({
        id: adoMaster, server: 'indexhu.adocean.pl', vars: typeof customTarget !== 'undefined' ? customTarget : '', keys: tagStr,
      })
    } else {
      ado.refresh(adoMaster)
    }

    ado.onMaster(adoMaster, () => {
      const adPlaces = document.querySelectorAll('.iap:not(.hidden)')
      adPlaces.forEach((e) => {
        if (e.querySelector('div').style.display === 'none') {
          e.style.display = 'none'
        }
      })
    })

  // ado.refresh('6tFLGnRzmJ8IZom4hL307bmlgi.AEx4LBXxN8yicEub.A7');
  }, 2000)
}

export const onInitialClientRender = () => {
  const adoMasterProp = window.location.pathname == '/' ? 'mainpage' : 'subpage'
  const bannerType = isMobile ? 'mobile' : 'desktop'
  const adoMaster = ADOCEAN_IDS[bannerType].master[adoMasterProp]

  setTimeout(() => {
    window.adocf = {}
    window.adocf.useDOMContentLoaded = true
    const tagStr = document.querySelector('meta[name="keywords"]') ? document.querySelector('meta[name="keywords"]').getAttribute('content').replace(/\s/g, '') : ''
    if (typeof ado !== 'object') { ado = {}; ado.config = ado.preview = ado.placement = ado.master = ado.slave = function () {}; ado.blocked = true }
    ado.config({
      mode: 'new', xml: false, consent: true, characterEncoding: true, attachReferrer: true, fpc: 'auto', defaultServer: 'indexhu.adocean.pl', cookieDomain: 'SLD',
    })
    ado.preview({ enabled: true })
    ado.master({
      id: adoMaster, server: 'indexhu.adocean.pl', vars: typeof customTarget !== 'undefined' ? customTarget : '', keys: tagStr,
    })
  }, 500)
}

export const wrapRootElement = AppWrapper
